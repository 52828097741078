import { useMainStore, useExcavationStore, useReconStore } from '@/stores'
import { getExcavationsChanges, blobToBase64, getDataChanges, getUuid } from '@/utils'
import db from '@/libs/db'
import cloneDeep from 'lodash.clonedeep'
import { useRequests, useRitmDate } from '@/composables'

const urls = {
  excavations: (id) => {
    return `excavations/${id}/images/`
  },
  samples: (id) => {
    return `samples/${id}/images/`
  },
  recons: (id, image_type) => {
    if (image_type === 'images') {
      return `reconnaissance/${id}/images/`
    } else {
      return `reconnaissance/${id}/journal/`
    }
  }
}

const deleteUrls = {
  excavations: (id) => {
    return `excavation-images/${id}/`
  },
  samples: (id) => {
    return `sample-image/${id}/`
  },
  recons: (id, image_type) => {
    if (image_type === 'images') {
      return `reconnaissance-images/${id}/`
    } else {
      return `reconnaissance-journal-images/${id}/`
    }
  }
}

export const deleteImages = async ({
  table,
  files,
  source_id,
  source,
  excavation_id,
  image_type
}) => {
  const { deleteRequest } = useRequests()
  const mainStore = useMainStore()

  try {
    await Promise.all(
      files.map(async ({ idb, id }) => {
        const url = deleteUrls[table]?.(id, image_type)
        if (idb) {
          const createdItem = await db.created.where({ item_id: id }).first()
          await db.images.delete(id)
          await db.created.delete(createdItem.id)
        } else if (mainStore.isOnline && !mainStore.noSyncMode) {
          await deleteRequest(url)
        } else {
          await db.deleted.add({
            table: 'images',
            item_id: id,
            date: new Date(),
            delete_url: url,
            object_id: source.object_id,
            title: source.title,
            source_id,
            excavation_id
          })

          if (table === 'excavations') {
            getExcavationsChanges()
          } else {
            getDataChanges()
          }
        }
      })
    )
  } catch (e) {
    throw new Error(e)
  }
}

export const saveImages = async ({
  table,
  item_id,
  source_id,
  server_id,
  files,
  image_type,
  excavation_id
}) => {
  const mainStore = useMainStore()
  const excavationStore = useExcavationStore()
  const reconStore = useReconStore()
  const rDate = useRitmDate()

  await Promise.all(
    files.map(async (image, i) => {
      const data = {
        server_id,
        table,
        item_id,
        source_id,
        image,
        image_type,
        excavation_id,
        uuid: getUuid()
      }
      const time = rDate(new Date()).format('iso')

      if (mainStore.isOnline && !mainStore.noSyncMode && server_id) {
        // Проверка на разные сущности и сторы
        if (table === 'recons') {
          reconStore.reconAddItem('imageLoading', {
            id: time + i,
            uploaded: false
          })
          await createImage(data)
          reconStore.reconUpdateItem('imageLoading', {
            id: time + i,
            uploaded: true
          })
        } else {
          excavationStore.excavationAddItem('imageLoading', {
            id: time + i,
            uploaded: false
          })
          await createImage(data)
          excavationStore.excavationUpdateItem('imageLoading', {
            id: time + i,
            uploaded: true
          })
        }
      } else {
        await saveImageOnIdb(data)
      }
    })
  )

  if (!mainStore.isOnline || mainStore.noSyncMode) {
    // Ошибка была тут так как тут не только table excavations а также samples  и тд.
    if (table === 'recons') {
      getDataChanges()
    } else {
      getExcavationsChanges()
    }
  }
}

export const createImage = async (data) => {
  try {
    await saveImageOnServer(data)
  } catch (e) {
    saveImageOnIdb(data)
    throw e
  }
}

export const saveImageOnServer = async ({ table, image, item_id, image_type, uuid }) => {
  if (!item_id) return
  try {
    const { postRequest } = useRequests()
    const { file, name: title } = image
    const image_b64 = await blobToBase64(file)

    const data = {
      title,
      item_id,
      image_b64,
      date_front: new Date(),
      uuid
    }

    const item = await db[table].where({ id: item_id }).first()

    if (item) {
      const url = urls[table]?.(item.server_id, image_type)
      await postRequest(url, data)
    }
  } catch (e) {
    if (e.response && e?.response?.status === 409) {
      e.response.data.item_id = item_id
    }
    throw e
  }
}

export const saveImageOnIdb = async ({
  image,
  source_id,
  item_id,
  table,
  image_type,
  excavation_id,
  uuid
}) => {
  const excavationStore = useExcavationStore()
  const reconStore = useReconStore()

  const imageLocal = cloneDeep(image)

  const data = {
    image: imageLocal,
    source_id,
    item_id,
    table,
    image_type: image_type ? image_type : null,
    date_front: new Date(),
    excavation_id,
    uuid
  }

  const id = await db.images.add(data)

  await db.created.add({
    table: 'images',
    date: new Date(),
    item_id: id,
    image_type: image_type ? image_type : null,
    excavation_id,
    uuid
  })

  imageLocal.blob = imageLocal.file
  if (image_type) {
    switch (image_type) {
      case 'journal': {
        reconStore.reconAddItem('reconJournalImages', imageLocal)
        break
      }
      case 'common': {
        reconStore.reconAddItem('reconCommonImages', imageLocal)
      }
    }
  } else if (data.table === 'excavations') {
    excavationStore.excavationAddItem('excavationImages', imageLocal)
  }
}
