<script setup>
import { ref } from 'vue'
const props = defineProps({
  currentType: {
    type: Object,
    required: true
  },
  options: {
    type: Object,
    required: true
  },
  enabledOptions: {
    type: Array,
    default: () => []
  }
})

const emits = defineEmits(['on-change'])

const spelled = ref(props.options.spelled)
const stamp = ref(props.options.stamp)
const type = ref(props.options.type)
const build_in_template = ref(props.currentType.build_in_template)

const radioHndl = (value) => {
  type.value = value
  emits('on-change', ['type', value])
}

const hasSetting = (setting) => {
  return props.enabledOptions.includes(setting)
}

const selectHandler = (value) => {
  build_in_template.value = value
  emits('on-change', ['build_in_template', value])
}
</script>

<template>
  <div class="export-settings">
    <s-title type="small">Настройки экспорта</s-title>
    <ul class="export-settings__list">
      <li v-if="hasSetting('type')" class="export-settings__item">
        <s-text class="export-settings__item-label" type="secondary"> Тип отчета </s-text>
        <s-radio-group>
          <s-radio-button :active-label="type" @change="radioHndl" label="full">
            Полный
          </s-radio-button>
          <s-radio-button :active-label="type" @change="radioHndl" label="short">
            Сокращенный
          </s-radio-button>
        </s-radio-group>
      </li>
      <li v-if="hasSetting('stamp')" class="export-settings__item">
        <s-switch
          v-model="stamp"
          label="Цифровой штамп"
          @change="emits('on-change', ['stamp', stamp])"
        />
      </li>
      <li v-if="hasSetting('spelled')" class="export-settings__item">
        <s-switch
          v-model="spelled"
          label="Проверка правописания"
          @change="emits('on-change', ['spelled', spelled])"
        />
      </li>

      <li v-if="currentType?.templatesList?.length > 1" class="export-settings__item">
        <s-select
          select-label="Шаблон отчета"
          :value="build_in_template"
          :options="currentType.templatesList"
          @change="selectHandler"
        />
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
.export-settings {
  &__list {
    margin-top: 16px;
  }

  &__item {
    &:not(:first-child) {
      margin-top: 24px;
    }

    &-label {
      margin-bottom: 8px;
    }

    .s-radio-button {
      flex: 1;
    }
  }
}
</style>
