import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

import { getDataFromLS, getOS, saveDataToLS, toggleBodyTheme } from '@/utils'

export const useMainStore = defineStore('main', () => {
  // common
  const loading = ref(false)
  const setLoading = (value) => {
    loading.value = value
  }

  const theme = ref(getDataFromLS('theme') || 'dark-theme')
  const setTheme = (value) => {
    theme.value = value

    saveDataToLS('theme', value)
    toggleBodyTheme(value)
  }

  // sync
  const noSyncMode = ref(false)
  const setNoSyncMode = (value) => {
    noSyncMode.value = value
  }

  const changesLoaded = ref(false)

  const setChangesLoaded = (value) => {
    changesLoaded.value = value
  }

  // last routes
  const lastRoutePaths = ref({
    lastObjectPath: null,
    lastBookPath: null
  })
  const setLastRoutePath = ([field, value]) => {
    lastRoutePaths.value[field] = value
  }

  // device OS
  const deviceOS = computed(() => {
    return getOS()
  })

  const version = import.meta.env.VITE_APP_RELEASE_VERSION || 'DEV'

  const isShowInfoModal = ref(false)
  const toggleInfoModal = () => {
    isShowInfoModal.value = !isShowInfoModal.value
  }

  const isShowUpdatesModal = ref(false)
  const toggleUpdatesModal = () => {
    isShowUpdatesModal.value = !isShowUpdatesModal.value
  }

  const isOnline = ref(navigator.onLine)
  const setIsOnline = (value) => {
    isOnline.value = value
  }

  const isAuthErrorNotified = ref(false)
  const setIsAuthErrorNotified = (value) => {
    isAuthErrorNotified.value = value
  }

  const license = ref('нет данных')
  const usersCount = ref('нет данных')

  const setLicenseAndUsersCount = (licenseValue = 'нет данных', usersCountValue = 'нет данных') => {
    license.value = licenseValue
    usersCount.value = usersCountValue
  }

  return {
    isShowUpdatesModal,
    toggleUpdatesModal,
    loading,
    setLoading,
    theme,
    setTheme,
    noSyncMode,
    setNoSyncMode,
    changesLoaded,
    setChangesLoaded,
    lastRoutePaths,
    setLastRoutePath,
    deviceOS,
    version,
    isShowInfoModal,
    toggleInfoModal,
    isOnline,
    setIsOnline,
    isAuthErrorNotified,
    setIsAuthErrorNotified,
    license,
    usersCount,
    setLicenseAndUsersCount
  }
})
