<script setup>
import { getLayerInfoFromData } from '@/utils'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  soilInterval: {
    type: Object,
    required: true
  },
  type: {
    type: String,
    default: ''
  },
  tip: {
    type: String,
    default: null
  }
})

const getIconName = () => {
  return props.type !== 'warning' ? 'circle-info' : 'circle-exclamation'
}

const getName = () => {
  if (props.tip) return props.tip

  const from = Number(props.soilInterval.from) ? Number(props.soilInterval.from)?.toFixed(1) : '0.0'
  const to = Number(props.soilInterval?.to) ? Number(props.soilInterval.to)?.toFixed(1) : '0.0'
  const depth = `${from} - ${to} м`
  const description = getLayerInfoFromData(props.data)

  if (props.type === 'warning') {
    return `Нельзя создать слой с нулевой мощностью (${depth})`
  }

  return `${depth}. ${description}`
}
</script>

<template>
  <div :class="[`create-soil-info ${type}`]">
    <s-icon :name="getIconName()" size="lg" :color="type ? `var(--${type})` : 'var(--primary)'" />
    <s-text>
      {{ getName() }}
    </s-text>
  </div>
</template>

<style lang="scss">
.create-soil-info {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.75rem;
  padding: 0.75rem;
  justify-content: start;
  align-items: center;
  background-color: var(--primary--select);
  border-radius: var(--border-radius);

  &.warning {
    background-color: var(--warning--select);
  }

  &.error {
    background-color: var(--error--select);
  }
}
</style>
