<script setup>
import { inject, reactive } from 'vue'
import { useAuthStore } from '@/stores'
import { useRequests } from '@/composables'
import { useVuelidate } from '@vuelidate/core'
import { basicInfoValidators } from './validator'
import SInputRenderer from '@/components/s-input-renderer/s-input-renderer.vue'

const emits = defineEmits(['toggle-loader'])

const authStore = useAuthStore()
const { patchRequest } = useRequests()
const $notify = inject('$notify')

const infoInputs = [
  {
    id: 'first_name',
    label: 'Имя',
    title: 'first_name',
    type: 'string'
  },
  {
    id: 'last_name',
    label: 'Фамилия',
    title: 'last_name',
    type: 'string'
  },
  {
    id: 'email',
    title: 'email',
    label: 'Электронная почта',
    type: 'string'
  }
]

let form = reactive(authStore.user)
const v$ = useVuelidate(basicInfoValidators, form)

const saveInfo = async () => {
  const result = await v$.value.$validate()

  if (!result) {
    return
  }

  try {
    emits('toggle-loader', true)

    const url = 'users/profile/'
    const data = {
      ...form
    }

    await patchRequest(url, data)
    await authStore.loadUserInfo()

    $notify({
      title: 'Сохранено',
      message: 'Профиль успешно сохранен',
      type: 'success'
    })
  } catch (error) {
    console.log(error)
  } finally {
    emits('toggle-loader', false)
  }
}
</script>

<template>
  <div class="profile-editor__block">
    <div class="profile-editor__block-title">
      <s-title type="small"> Персональные данные </s-title>
    </div>

    <div class="profile-editor__inputs" v-if="form">
      <div v-for="row in infoInputs" :key="row.id" class="profile-editor__row">
        <div class="profile-editor__input">
          <s-input-renderer :field="row" :source="form" />
          <span class="profile-editor__input-error" v-if="v$[row.id].$error">{{
            v$[row.id].$errors[0].$message
          }}</span>
        </div>
      </div>
    </div>

    <div class="profile-editor__block-button">
      <s-button @click="saveInfo" type="primary"> Сохранить </s-button>
    </div>
  </div>
</template>

<style lang="scss">
.profile-editor__input {
  &-error {
    color: var(--error);
    font-size: 0.8em;
    text-align: left;
  }
}
</style>
