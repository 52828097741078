import { getFromService } from './index'

export const getLargeScaleDescription = (parent, data) => {
  let soilDescription = parent?.soilName + (parent?.comment ? `, ${parent?.comment}` : '')

  const waterSaturationAttr = { id: 'water_saturation', service: 'water_saturations_sand' }
  const waterSaturation = getFromService(
    parent?.soilSource,
    data,
    waterSaturationAttr,
    parent?.soilServices
  )

  if (waterSaturation) {
    soilDescription += `, ${waterSaturation}`
  }

  if (parent?.waterSaturationComment) {
    soilDescription += `, ${parent.waterSaturationComment}`
  }

  if (parent?.soilSource) {
    const items1 = attrs1.map((e) => {
      if (e.id === 'filler_water_saturation') {
        e.service =
          parent?.soilServices?.fillers_types?.find((e) => e.id === data.filler_type)?.api_dict ||
          'water_saturations_sand'
      }

      return getFromService(parent?.soilSource, data, e, parent?.soilServices)
    })

    const filteredItems1 = items1.filter((e) => e)

    if (filteredItems1?.length) {
      filteredItems1.forEach((e) => {
        soilDescription += `, ${e}`
      })
    }
  }

  parentAttrs1.forEach((a) => {
    if (parent[a]) {
      soilDescription += `, ${parent[a]?.toLowerCase()}`
    }
  })

  let hasDebris = false

  if (parent?.soilSource) {
    const items2 = attrs2.map((e) => {
      const serviceInfo = getFromService(parent?.soilSource, data, e, parent?.soilServices)

      const value = e.subInfo && serviceInfo ? e.subInfo + ' ' + serviceInfo : serviceInfo

      if (e.id !== 'debris_subinfo') {
        if (value && debrisAttrs.includes(e.id)) {
          hasDebris = true
        }

        return {
          ...e,
          value
        }
      } else {
        return e
      }
    })

    const filteredItems2 = items2.filter((e) => {
      if (e.id === 'debris_subinfo') {
        return hasDebris
      } else {
        return e.value
      }
    })

    if (filteredItems2?.length) {
      filteredItems2.forEach((e) => {
        const comma = !e.noComma ? ',' : ''

        soilDescription += `${comma} ${e.value}`
      })
    }
  }

  return soilDescription
}

const attrs1 = [
  { id: 'filler_type', service: 'fillers_types' },
  { id: 'filler_amount', service: 'fillers_amounts' },
  { id: 'filler_water_saturation', service: 'filler_saturations' },
  { id: 'filler_comments' }
]

const parentAttrs1 = ['color', 'colorComment']

const attrs2 = [
  { id: 'text_thaw', service: 'texts_thaw' },
  { id: 'struct_text_comments' },
  { id: 'debris_subinfo', value: 'преобладают' },
  {
    id: 'debris_round',
    service: 'debris_rounds',
    noComma: true,
    titleEnd: 'end3'
  },
  { id: 'debris_subinfo', value: 'обломки', noComma: true },
  {
    id: 'debris_composition',
    service: 'soil_types',
    filterValue: '20000;29999',
    noComma: true,
    titleKey: 'title_gen'
  },
  { id: 'debris_round_comments' },
  { id: 'strength', service: 'strengths', titleEnd: 'end3' },
  { id: 'strength_comments' }
]

const debrisAttrs = ['debris_round', 'debris_composition', 'strength']
