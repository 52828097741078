export const frozenLargeScaleFields = {
  basic: [
    {
      id: 'filler',
      title: 'Укажите информацию о заполнителе',
      type: 'group',
      wikiTipData: {
        color: 'placeholder',
        title: 'Заполнитель',
        description:
          'В крупнообломочных грунтах необходимо указывать вид и процентное содержание заполнителя. При наличии в крупнообломочных грунтах песчаного заполнителя более 40 % или глинистого заполнителя более 30 % общей массы воздушно-сухого грунта в наименование крупнообломочного грунта включают наименование вида заполнителя и указывают характеристики его состояния (влажность, плотность, показатель текучести). Вид заполнителя устанавливают после удаления из крупнообломочного грунта частиц крупнее 2 мм.',
        source:
          'ГОСТ 25100. Грунты. Классификация. НИИОСП им. Н.М. Герсеванова» — АО «НИЦ «Строительство», 2020'
      },
      groups: [
        {
          id: 'filler_type',
          title: 'filler_type',
          label: 'Вид грунта',
          type: 'select',
          service: 'fillers_types'
        },
        {
          id: 'filler_amount',
          title: 'filler_amount',
          label: 'Количество',
          type: 'select',
          service: 'fillers_amounts'
        }
      ],
      commentField: 'filler_comments',
      commentTitle: 'Комментарий к заполнителю'
    },
    {
      id: 'state_pfrost',
      title: 'state_pfrost',
      label: 'Укажите состояние грунта',
      type: 'state-pfrost',
      service: 'states_pfrost',
      commentField: 'water_saturation_comments',
      commentTitle: 'Комментарий к состоянию / консистенции'
    },
    {
      id: 'text_pfrost',
      title: 'text_pfrost',
      label: 'Укажите криогенную текстуру грунта',
      type: 'select',
      service: 'texts_pfrost',
      commentField: 'struct_text_comments',
      commentTitle: 'Комментарий к криогенной текстуре',
      wikiTipData: {
        color: 'placeholder',
        title: 'Криогенная текстура',
        description:
          'Совокупность признаков сложения мерзлого грунта, обусловленная ориентировкой, относительным расположением и распределением включений текстурообразующего льда и минеральных агрегатов',
        source:
          'ГОСТ Р 58325. Грунты. Полевое Описание, п. 3.4.5. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
      }
    },
    {
      id: 'text_thaw',
      title: 'text_thaw',
      label: 'Укажите текстуру грунта',
      type: 'select',
      service: 'texts_thaw',
      commentField: 'struct_text_comments',
      commentTitle: 'Комментарий к текстуре'
    },
    {
      id: 'debris',
      type: 'group',
      groups: [
        {
          label: 'Укажите степень окатанности обломков',
          id: 'debris_round',
          title: 'debris_round',
          type: 'select',
          service: 'debris_rounds'
        },
        {
          label: 'Укажите состав обломков',
          id: 'debris_composition',
          title: 'debris_composition',
          type: 'select',
          service: 'soil_types',
          filterValue: '20000;29999'
        }
      ],
      commentField: 'debris_round_comments',
      commentTitle: 'Комментарий к составу обломков'
    },
    {
      id: 'strength',
      title: 'strength',
      label: 'Укажите прочность крупных обломков',
      type: 'select',
      service: 'strengths',
      commentField: 'strength_comments',
      commentTitle: 'Комментарий к прочности обломков'
    }
  ],
  additional: true,
  inclusions: true,
  interlayers: true
}
