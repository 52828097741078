<script setup>
const props = defineProps({
  currentStep: {
    type: Number,
    required: true
  },
  itemStylesObject: {
    type: Object,
    required: false
  },
  isObjectCreate: {
    type: Boolean,
    default: false
  },
  steps: {
    type: Array,
    required: true
  }
})

const emits = defineEmits(['change-step-handler'])

const titleItemCounter = (count, title) => {
  if (!props.isObjectCreate) {
    return title
  }

  return `${count}. ${title}`
}
</script>

<template>
  <ul class="steps-list">
    <li
      :style="itemStylesObject"
      @click="emits('change-step-handler', step.step)"
      :key="step.step"
      v-for="step in steps"
    >
      <s-text :class="{ 'current-step': currentStep === step.step }">
        {{ titleItemCounter(step.step, step.title) }}
      </s-text>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.steps-list {
  & li {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & .s-text.current-step {
    color: var(--primary);
  }
}
</style>
