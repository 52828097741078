<script setup>
import { ref, computed, watch } from 'vue'
import { baselayers, mapStyles } from '@/configs/map'
import { useMainStore, useMapStore } from '@/stores'

import MapButton from './map-button.vue'

const props = defineProps({
  mapgl: {
    type: Object,
    required: true
  },
  module: {
    type: String,
    default: 'data-map'
  }
})

const mainStore = useMainStore()
const mapStore = useMapStore()

const showList = ref(false)

const baselayer = computed({
  get() {
    return mapStore.activeBaselayers[props.module]
  },
  set(value) {
    mapStore.setBaselayer(props.module, value)

    const style = mapStore.getActiveBaselayerName(props.module)
    props.mapgl.setStyle(mapStyles[style])
  }
})
const clickHandler = (id) => {
  if (baselayer.value === id) return

  baselayer.value = id
  showList.value = false
}

const getClassName = (id) => {
  if (id === baselayer.value) {
    return 'map-baselayers__item map-baselayers__item--active'
  }

  return 'map-baselayers__item'
}

watch(
  () => mainStore.theme,
  () => {
    const active = mapStore.activeBaselayers[props.module]

    if (active === 'map') {
      props.mapgl.setStyle(mapStyles[mapStore.getActiveBaselayerName(props.module)])
    }
  }
)
</script>

<template>
  <div class="map-baselayers">
    <div v-tooltip="'Слои'">
      <s-pop-tip v-model="showList" width="160" trigger="click">
        <map-button icon="layer-group" :active="showList" size="lg" />
        <template #content>
          <ul class="map-baselayers__list">
            <li
              v-for="layer in baselayers"
              :key="layer.id"
              :class="getClassName(layer.id)"
              @click="clickHandler(layer.id)"
            >
              <s-text>{{ layer.name }}</s-text>
            </li>
          </ul>
        </template>
      </s-pop-tip>
    </div>
  </div>
</template>

<style lang="scss">
.map-baselayers {
  position: absolute;
  top: 16px;
  right: 16px;

  &__list {
    margin: 0;
    padding: 0;
  }

  & svg {
    width: 22px;
    height: 22px;
  }

  &__item {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;

    &:not(:first-child) {
      margin-top: 4px;
    }

    &:hover {
      background-color: var(--primary--select);
    }

    &--active {
      background-color: var(--primary--active);
    }
  }
}
</style>
