export const exportGroups = [
  { id: 'excavations', title: 'Скважины' },
  { id: 'recons', title: 'Точки наблюдения' }
]

export const exportTypesConfig = [
  {
    id: 'json',
    label: 'Выгрузка в EngGeo',
    description: 'JSON-файл',
    icon: 'database',
    options: ['spelled'],
    api: 'json_export',
    extension: 'json',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  {
    id: 'column',
    label: 'Колонки скважин',
    description: 'Документ Word',
    icon: 'file-word',
    api: 'column_export',
    extension: 'docx',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report',
    templatesList: [
      {
        id: 'column_export_full',
        api: 'column_export_full',
        title: 'Полный'
      },
      {
        id: 'column_export_short',
        api: 'column_export_short',
        title: 'Сокращенный'
      }
    ],
    build_in_template: 'column_export_full'
  },
  {
    id: 'bore_journal',
    label: 'Цифровой буровой журнал',
    description: 'Документ Word',
    icon: 'file-word',
    api: 'digital_report',
    templatesList: [
      {
        id: 'journal_GOST',
        api: 'excavations_export',
        title: 'Сокращенный (без абриса и адреса, без статистики)'
      },
      {
        id: 'digital_journal_new',
        api: 'digital_report',
        title: 'Упрощенный (с абрисом и адресом, без статистики)'
      },
      {
        id: 'digital_journal_new_full',
        api: 'digital_report',
        title: 'Полный (с абрисом и адресом, со статистикой)'
      }
    ],
    build_in_template: 'journal_GOST',
    extension: 'docx',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  {
    id: 'excavations_photos_core_export',
    label: 'Все фото',
    description: 'Документ Word',
    icon: 'file-word',
    options: ['stamp'],
    api: 'excavations_photos_core_export',
    templatesList: [
      {
        id: 'excavations_photos_core_export',
        api: 'excavations_photos_core_export',
        title: 'Стандартный'
      }
    ],
    build_in_template: 'excavations_photos_core_export',
    extension: 'docx',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  {
    id: 'process_v2',
    label: 'Общие фото скважин',
    description: 'Документ Word',
    icon: 'file-word',
    options: ['stamp'],
    api: 'excavations_process_export',
    build_in_template: 'excavations_process_export',
    templatesList: [
      {
        id: 'excavations_process_export',
        api: 'excavations_process_export',
        title: 'Стандартный'
      }
    ],
    extension: 'docx',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  {
    id: 'process_files',
    label: 'Общие фото скважин',
    description: 'Архив фото',
    icon: 'file-image',
    options: null,
    api: 'process_files',
    extension: 'zip',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  // {
  //   id: 'sample',
  //   label: 'Фотографии проб',
  //   description: 'Документ Word',
  //   icon: 'file-word',
  //   options: null,
  //   excavations: true,
  //   api: 'sample_exports',
  //   extension: 'docx',
  //   group: 'excavations'
  // },
  {
    id: 'sample_v2',
    label: 'Фотографии проб',
    description: 'Документ Word',
    icon: 'file-word',
    options: ['stamp'],
    api: 'sample_export',
    build_in_template: 'sample_export',
    templatesList: [
      {
        id: 'sample_export',
        api: 'sample_export',
        title: 'Стандартный'
      }
    ],
    extension: 'docx',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  {
    id: 'samples_files',
    label: 'Фотографии проб',
    description: 'Архив фото',
    icon: 'file-image',
    options: null,
    api: 'samples_files',
    extension: 'zip',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  {
    id: 'sample_sheet',
    label: 'Ведомость проб',
    description: 'Документ Word',
    icon: 'file-word',
    options: ['stamp'],
    templatesList: [
      {
        id: 'sample_sheet_export',
        api: 'sample_sheet_export',
        title: 'Стандартный'
      }
    ],
    build_in_template: 'sample_sheet_export',
    api: 'sample_sheet_export',
    extension: 'docx',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  {
    id: 'excel_sample_sheet_export',
    label: 'Ведомость проб',
    description: 'Документ Excel',
    icon: 'file-excel',
    api: 'excel_sample_sheet_export',
    options: ['spelled'],
    build_in_template: 'excel_sample_sheet_export',
    templatesList: [
      {
        id: 'excel_sample_sheet_export',
        api: 'excel_sample_sheet_export',
        title: 'Стандартный'
      },
      {
        id: 'excel_sample_sheet_export_full',
        api: 'excel_sample_sheet_export_full',
        title: 'Полный'
      }
    ],
    extension: 'xlsx',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  // {
  //   id: 'core',
  //   label: 'Фотографии керна',
  //   description: 'Документ Word',
  //   icon: 'file-word',
  //   options: null,
  //   excavations: true,
  //   api: 'core_exports',
  //   extension: 'docx',
  //   group: 'excavations'
  // },
  {
    id: 'core_v2',
    label: 'Фотографии керна',
    description: 'Документ Word',
    build_in_template: 'core_export',
    templatesList: [
      {
        id: 'core_export',
        api: 'core_export',
        title: 'Стандартный'
      }
    ],
    icon: 'file-word',
    options: ['stamp'],
    api: 'core_export',
    extension: 'docx',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  {
    id: 'core_files',
    label: 'Фотографии керна',
    description: 'Архив фото',
    icon: 'file-image',
    options: null,
    api: 'core_files',
    extension: 'zip',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  {
    id: 'reconnaissance_photos_exports',
    label: 'Точки наблюдения',
    description: 'Документ Word',
    icon: 'file-word',
    options: ['stamp'],
    build_in_template: 'reconnaissance_photos_export',
    templatesList: [
      {
        id: 'reconnaissance_photos_export',
        api: 'reconnaissance_photos_export',
        title: 'Стандартный'
      }
    ],
    api: 'reconnaissance_photos_export',
    extension: 'docx',
    group: 'recons',
    selectList: true,
    dataType: 'recons',
    selectParam: 'recons_to_report'
  },
  {
    id: 'reconnaissance_journals_exports',
    label: 'Фото журналов ТН',
    description: 'Документ Word',
    icon: 'file-word',
    options: ['stamp'],
    build_in_template: 'reconnaissance_journals_export',
    templatesList: [
      {
        id: 'reconnaissance_journals_export',
        api: 'reconnaissance_journals_export',
        title: 'Стандартный'
      }
    ],
    api: 'reconnaissance_journals_export',
    extension: 'docx',
    group: 'recons',
    selectList: true,
    dataType: 'recons',
    selectParam: 'recons_to_report'
  },
  {
    id: 'recon_images_files',
    label: 'Общие фото ТН',
    description: 'Архив фото',
    icon: 'file-image',
    options: null,
    api: 'recon_images_files',
    extension: 'zip',
    group: 'recons',
    selectList: true,
    dataType: 'recons',
    selectParam: 'recons_to_report'
  },
  {
    id: 'recon_journal_files',
    label: 'Фото журналов ТН',
    description: 'Архив фото',
    icon: 'file-image',
    options: null,
    api: 'recon_journal_files',
    extension: 'zip',
    group: 'recons',
    selectList: true,
    dataType: 'recons',
    selectParam: 'recons_to_report'
  }
]
