export const startSteps = {
  1: {
    id: 1,
    comp: 'location',
    title: 'Укажите фактическое местоположение'
  },
  2: {
    id: 2,
    comp: 'inputs',
    title: 'Укажите информацию о бурении'
  },
  3: {
    id: 3,
    comp: 'inputs',
    title: 'Укажите природные условия'
  },
  4: {
    id: 4,
    comp: 'images',
    title: 'Добавьте фотографии общего вида'
  }
}

export const drillFields = [
  {
    id: 'date_from',
    title: 'date_from',
    format: 'dd.MM.yyyy',
    label: 'Дата начала',
    type: 'date',
    setCurrentDate: true,
    dateOptions: {
      min: null,
      max: null,
      condition: {
        field: 'date_to',
        value: 'less'
      }
    }
  },
  {
    id: 'date_to',
    title: 'date_to',
    format: 'dd.MM.yyyy',
    label: 'Дата окончания',
    type: 'date',
    dateOptions: {
      min: null,
      max: 'today',
      condition: {
        field: 'date_from',
        value: 'more'
      }
    }
  },
  {
    id: 12,
    title: 'method',
    label: 'Тип бурения',
    type: 'select',
    service: 'methods'
  },
  {
    id: 13,
    title: 'casing',
    label: 'Крепление / обсадка',
    type: 'select',
    service: 'casings'
  },
  {
    id: 14,
    title: 'backfill',
    label: 'Засыпка / тампонаж',
    type: 'select',
    service: 'backfills'
  },
  {
    id: 15,
    title: 'diam1',
    label: 'Начальный диаметр, мм',
    placeholder: '168',
    type: 'number',
    min: 0
  },
  {
    id: 16,
    title: 'diam2',
    label: 'Конечный диаметр, мм',
    placeholder: '96',
    type: 'number',
    min: 0
  },
  {
    id: 17,
    title: 'bore_machine',
    label: 'Буровая установка',
    type: 'select',
    service: 'object_bore_machines'
  },
  {
    id: 18,
    title: 'bore_master',
    label: 'Буровой мастер',
    type: 'select',
    service: 'object_bore_masters'
  }
]

export const natureFields = [
  {
    id: 19,
    title: 'geomorph_id',
    label: 'Геоморфология',
    type: 'select',
    service: 'geomorphies',
    wikiTipData: {
      color: 'placeholder',
      title: 'Геоморфология',
      description:
        'Полевая документация описания грунтов в обязательном порядке также содержит следующую дополнительную информацию: положение выработки в рельефе (на неосвоенных территориях)',
      source:
        'ГОСТ Р 58325. Грунты. Полевое Описание, п. 4.1.5. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
    }
  },
  {
    id: 20,
    title: 'geomorph_comments',
    label: 'Комментарий к геоморфологии',
    type: 'textarea',
    rows: 3
  },
  {
    id: 21,
    title: 'natural_f',
    label: 'Природные условия',
    type: 'string',
    wikiTipData: {
      color: 'placeholder',
      title: 'Природные условия',
      description:
        'При описании горных выработок на многолетнемерзлых грунтах проводят описание растительного и снежного покровов (при наличии) на момент проходки, дают характеристику положения выработки в рельефе, глубину сезонного промерзания (оттаивания) на момент бурения.',
      source:
        'ГОСТ Р 58325. Грунты. Полевое Описание, п. 7.1.6. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
    }
  },
  {
    id: 22,
    title: 'artificial_f',
    label: 'Техногенные условия',
    type: 'string',
    wikiTipData: {
      color: 'placeholder',
      title: 'Техногенные условия',
      description:
        'Полевая документация описания грунтов в обязательном порядке также содержит следующую дополнительную информацию: координаты или ситуационная привязка (на застроенных территориях)',
      source:
        'ГОСТ Р 58325. Грунты. Полевое Описание, п. 4.1.5. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
    }
  }
]
