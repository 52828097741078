export const frozenFields = {
  basic: [
    {
      id: 'text_pfrost',
      title: 'text_pfrost',
      label: 'Укажите криогенную текстуру грунта',
      type: 'select',
      service: 'texts_pfrost',
      commentField: 'struct_text_comments',
      commentTitle: 'Комментарий к криогенной текстуре',
      wikiTipData: {
        color: 'placeholder',
        title: 'Криогенная текстура',
        description:
          'Совокупность признаков сложения мерзлого грунта, обусловленная ориентировкой, относительным расположением и распределением включений текстурообразующего льда и минеральных агрегатов',
        source:
          'ГОСТ Р 58325. Грунты. Полевое Описание, п. 3.4.5. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
      }
    },
    {
      id: 'ice_content',
      title: 'ice_content',
      label: 'Укажите льдистость грунта',
      type: 'ice-content',
      commentField: 'ice_content_comments',
      commentTitle: 'Комментарий к льдистости',
      wikiTipData: {
        color: 'placeholder',
        title: 'Льдистость',
        description:
          'Полевое описание грунтового льда (включения и прослои свыше 10 см мощностью) при колонковом бурении включаете себя цвет, наличие и ориентацию трещин, включений (грунта или пузырьков газа)',
        source:
          'ГОСТ Р 58325. Грунты. Полевое Описание, п. 7.1.4. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
      }
    },
    {
      id: 'state_pfrost',
      title: 'state_pfrost',
      label: 'Укажите состояние грунта',
      type: 'state-pfrost',
      service: 'states_pfrost',
      commentField: 'water_saturation_comments',
      commentTitle: 'Комментарий к состоянию / консистенции'
    }
  ],
  additional: true,
  inclusions: true,
  interlayers: true
}
