import { useRoute, useRouter } from 'vue-router'
import { useRequests } from '@/composables'
import db from '@/libs/db'
import { saveToRecentList } from '@/utils/recent'
import { checkStorage, updateStorage } from '@/libs/db/helpers'
import {
  useExcavationStore,
  useMainStore,
  useServicesStore,
  useObjectsStore,
  useReconStore
} from '@/stores'

export class DataController {
  constructor() {
    this.excavationStore = useExcavationStore()
    this.mainStore = useMainStore()
    this.reconStore = useReconStore()
    this.servicesStore = useServicesStore()
    this.objectsStore = useObjectsStore()
    this.route = useRoute()
    this.router = useRouter()
    this.getRequest = useRequests().getRequest
    this.object = {}

    this.updateHandleFnMap = {
      recons: this.getRecons.bind(this),
      commonImages: this.getReconCommonImages.bind(this),
      journalImages: this.getReconJournalImages.bind(this)
    }
  }

  updateHandler(val) {
    if (!this.reconId()) return

    if (!this.updateHandleFnMap[val]) {
      this.getRecon()
      return
    }

    this.updateHandleFnMap[val]()
  }

  parsedId() {
    const id = !this.isIdb() ? this.reconId() : this.reconId().replace('idb_', '')
    return id ? Number(id) : null
  }

  isIdb() {
    return String(this.reconId()).includes('idb_')
  }

  reconId() {
    return this.route.query.id
  }

  recon() {
    return this.reconStore.active || {}
  }

  async loadAllAssets(fromType = 'initial') {
    await this.getRecon()
    await this.getReconCommonImages()
    await this.getReconJournalImages()

    if (fromType === 'initial') {
      await this.getRecons()
    }
  }

  async getRecon() {
    if (!this.reconId()) return
    if (this.mainStore.isOnline && !this.mainStore.noSyncMode) await this.loadReconOnline()
    else await this.loadReconOffline()
  }

  async getRecons() {
    if (!this.reconId()) return
    if (this.mainStore.isOnline && !this.mainStore.noSyncMode) await this.loadReconsOnline()
    else await this.loadExcavsOffline()
  }

  async getReconJournalImages() {
    if (!this.reconId()) return
    if (this.mainStore.isOnline && !this.mainStore.noSyncMode) {
      await this.loadReconJournalImagesOnline()
    } else {
      await this.loadReconJournalImagesOffline()
    }
  }

  async loadReconJournalImagesOnline() {
    try {
      const images = await this.getRequest(`reconnaissance/${this.parsedId()}/journal/`)
      this.reconStore.setField('reconJournalImages', images)
    } catch (e) {
      throw new Error(e)
    }
  }

  async loadReconJournalImagesOffline () {
    try {
      const images = []
      const idb = await db.images
        .where({
          table: 'recons',
          item_id: this.reconStore.active?.id,
          image_type: 'journal'
        })
        .toArray()

      idb.forEach((e) => {
        images.push({
          ...e,
          blob: e.image.file
        })
      })

      this.reconStore.setField('reconJournalImages', images)
    } catch (e) {
      throw new Error(e)
    }
  }

  async getReconCommonImages() {
    if (!this.reconId()) return
    if (this.mainStore.isOnline && !this.mainStore.noSyncMode) {
      await this.loadReconCommonImagesOnline()
    } else {
      await this.loadReconCommonImagesOffline()
    }
  }

  async loadReconCommonImagesOnline() {
    try {
      const images = await this.getRequest(`reconnaissance/${this.parsedId()}/images/`)
      this.reconStore.setField('reconCommonImages', images)
    } catch (e) {
      throw new Error(e)
    }
  }

  async loadReconCommonImagesOffline () {
    try {
      const images = []
      const idb = await db.images
        .where({
          table: 'recons',
          item_id: this.reconStore.active?.id,
          image_type: 'images'
        })
        .toArray()

      idb.forEach((e) => {
        images.push({
          ...e,
          blob: e.image.file
        })
      })

      this.reconStore.setField('reconCommonImages', images)
    } catch (e) {
      throw new Error(e)
    }
  }

  async loadReconOffline() {
    try {
      const filterField = this.isIdb() ? 'id' : 'server_id'
      const recon = await db.recons.where({ [filterField]: this.parsedId() }).first()

      if (!recon) {
        this.router.push('/app/not-found')
        return
      }

      saveToRecentList('recentExcavsId', recon.id)

      this.reconStore.setField('active', recon)
      await this.loadObject(recon?.object_id)
    } catch (e) {
      throw new Error(e)
    }
  }

  async loadReconOnline() {
    try {
      let recon

      if (this.isIdb()) {
        recon = await db.recons.get(this.parsedId())
      } else {
        const idb = await db.recons
          .where({
            server_id: this.parsedId()
          })
          .first()

        recon = await this.getRequest(`reconnaissance/${this.parsedId()}/`)
        recon.id = idb?.id
        recon.server_id = this.parsedId()
        recon.object_id = recon.object_ref_id || idb?.object_id

        await db.updateObject('recons', recon, false, true)
      }

      if (!recon) return
      saveToRecentList('recentExcavsId', recon.id)

      this.reconStore.setField('active', recon)

      await this.loadObject(recon?.object_id)
    } catch (e) {
      this.router.push('/app/not-found')
      throw new Error(e)
    }
  }

  async loadReconsOnline() {
    try {
      let object
      const objectId = this.recon()?.object_id
      if (!objectId) return
      const isIdbObject = String(objectId).includes('idb_')
      const parsedObjectId = !isIdbObject ? Number(objectId) : Number(objectId.replace('idb_', ''))

      if (isIdbObject) {
        object = await db.objects.get(parsedObjectId)
      } else {
        const idbObject = await db.objects
          .where({
            server_id: parsedObjectId
          })
          .first()
        const idbId = idbObject?.id
        object = await this.getRequest(`objects/${parsedObjectId}/`)

        object.id = idbId
        object.server_id = parsedObjectId
        if (idbId) {
          await db.updateObject('objects', object)
        } else {
          throw new Error('debug loadReconsOnline: ', idbId, idbObject)
        }
      }

      if (!object) return
      this.setProjectMetadata(object)

      this.objectsStore.setField('active', object)

      const filter = { field: 'object_id', value: parsedObjectId }

      await db.updateStoreData('recons', filter)

      const server = await this.getRequest(`objects/${parsedObjectId}/reconnaissance/`)

      await checkStorage('recons', this.objectsStore.reconsList, server, filter)
      await updateStorage('recons', this.objectsStore.reconsList, server, filter)
    } catch (e) {
      throw new Error(e)
    }
  }

  async loadExcavsOffline() {
    try {
      const objectId = this.recon()?.object_id
      const isIdbObject = String(objectId).includes('idb_')
      const filterField = isIdbObject ? 'id' : 'server_id'
      const parsedObjectId = !isIdbObject ? Number(objectId) : Number(objectId.replace('idb_', ''))

      const object = await db.objects.where({ [filterField]: parsedObjectId }).first()

      if (!object) return

      this.setProjectMetadata(object)

      this.objectsStore.setField('active', object)

      const filter = { field: 'object_id', value: parsedObjectId }

      await db.updateStoreData('recons', filter)
    } catch (e) {
      throw new Error(e)
    }
  }

  async loadObject(id) {
    try {
      if (!id) return

      const object = await db.objects.where('server_id').equals(id).first()

      if (!object) return

      this.object = object
      this.setProjectMetadata(object)
    } catch (e) {
      throw new Error(e)
    }
  }

  setProjectMetadata({ geologists, bore_machines, bore_masters }) {
    const object_users = this.services()?.users?.filter((e) => geologists?.find((g) => g === e.id))
    const object_bore_machines = this.services()?.bore_machines?.filter((e) =>
      bore_machines?.find((g) => g === e.id)
    )
    const object_bore_masters = this.services()?.bore_masters?.filter((e) =>
      bore_masters?.find((g) => g === e.id)
    )

    this.servicesStore.setService(['object_users', object_users])
    this.servicesStore.setService(['object_bore_machines', object_bore_machines])
    this.servicesStore.setService(['object_bore_masters', object_bore_masters])
  }

  services() {
    const { users, bore_machines, bore_masters } = this.servicesStore

    return { users, bore_machines, bore_masters }
  }
}
