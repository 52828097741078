<script setup>
import { ref } from 'vue'
import { useMainStore } from '@/stores'
import { syncExcavItem, syncDataItem } from '@/utils'
import { syncErrorHandler } from '../helper'
import DataItemIcon from '@/components/s-data-item/s-data-item-icon.vue'

const props = defineProps({
  info: {
    type: Object,
    default: () => null
  }
})

const mainStore = useMainStore()

const loading = ref(false)

const getExcavName = () => {
  let typeName
  if (props.info.type === 201) {
    typeName = 'ТН'
  } else {
    typeName = props.info.type === 2 ? 'ТСЗ' : 'Скв.'
  }

  const name = props.info.title || props.info.title === 0 ? props.info.title : '-'
  return typeName + ' ' + name
}

const syncData = async () => {
  try {
    loading.value = true
    // await getMeasureInternetSpeed('small')

    if (props.info.type === 201) {
      await syncDataItem(props.info)
    } else {
      await syncExcavItem(props.info)
    }
  } catch (e) {
    if (e?.response) {
      syncErrorHandler(e)
    }
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <li class="sync-content-list__item">
    <s-div space-between col>
      <s-div col :gap="1">
        <data-item-icon :type="info.type" />
        <div class="sync-content-list__item__title">
          <s-text type="info">
            {{ getExcavName() }}
          </s-text>
          <s-text>
            {{ info.object || '-' }}
          </s-text>
        </div>
      </s-div>
      <s-div col>
        <s-dropdown :max-width="256" stick-to="right">
          <s-button icon="circle-info" />
          <template #dropdown>
            <div class="sync-content-list__updates">
              <s-title type="small">Список изменений</s-title>
              <div>
                <s-div v-for="update in info.updates" :key="update.id">
                  <s-text>{{ `· ${update.title}` }}</s-text>
                </s-div>
              </div>
            </div>
          </template>
        </s-dropdown>
        <s-button
          v-loading="loading"
          type="success"
          icon="arrows-rotate"
          :disabled="!mainStore.isOnline"
          @click="syncData"
        />
      </s-div>
    </s-div>
  </li>
</template>

<style lang="scss">
.sync-content-list {
  &__item {
    display: grid;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
    justify-content: stretch;
    justify-items: space-between;
  }

  &__updates {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.light-theme {
  .sync-content-list__item:after {
    background-color: var(--dark-bg);
  }
}
</style>
