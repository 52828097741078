import { useAuthStore, useMainStore } from '@/stores'
import router from '@/router'
import appGlobalProperties from '@/main'

export const errorParser = (reject, error) => {
  const authStore = useAuthStore()
  const mainStore = useMainStore()

  const { response } = error
  const status = response ? response.status : null

  switch (status) {
    case 403:
    case 401: {
      window.stop()
      authStore.authLogout()

      router.push('/login')

      if (!mainStore.isAuthErrorNotified) {
        appGlobalProperties.$notify({
          message: 'Ошибка доступа. Перезайдите в систему',
          type: 'error'
        })
        mainStore.setIsAuthErrorNotified(true)

        setTimeout(() => {
          mainStore.setIsAuthErrorNotified(false)
        }, 3000)
      }
    }
  }

  reject(error)
  console.log('error parser', error)
  throw error
}
