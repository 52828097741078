<script setup>
import { computed, ref, watch } from 'vue'
import cloneDeep from 'lodash.clonedeep'
import { useRoute } from 'vue-router'
import { ObjectEditorSteps } from './config'
import { useObjectsStore } from '@/stores'
import { editObjectHelper, deleteObjectHelper } from './helpers'

const objectsStore = useObjectsStore()
const route = useRoute()

const props = defineProps({
  currentStep: {
    type: Number,
    required: true
  }
})

const isLoading = ref(false)

const activeComponent = computed(() => {
  return ObjectEditorSteps.find((step) => step.step === props.currentStep)
})

const object = computed(() => {
  return objectsStore.activeObject
})

const clone = ref(cloneDeep(object.value))

watch(
  () => objectsStore.activeObject,
  (newValue) => {
    if (newValue) {
      clone.value = cloneDeep(object.value)
    }
  },
  { deep: true }
)

const isLastStep = computed(() => {
  return props.currentStep === ObjectEditorSteps.length
})

const editObject = async () => {
  isLoading.value = true
  await editObjectHelper(clone.value)
  isLoading.value = false
}

const deleteObject = () => {
  const objectID = route.params.id
  deleteObjectHelper(objectID)
}
</script>

<template>
  <div class="object-editor">
    <component :fields="activeComponent.fields" :template="clone" :is="activeComponent.component" />
    <div class="object-editor__btn">
      <s-button @click="deleteObject" v-if="isLastStep" type="error" stretch>
        Удалить проект
      </s-button>
      <s-button :loading="isLoading" @click="editObject" v-else stretch type="success">
        {{ isLoading ? 'Изменение данных' : 'Сохранить изменения' }}
      </s-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.object-editor {
  width: 556px;
  padding: 24px;
  border: 1px solid var(--bg);
  background: var(--card-bg);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  justify-content: space-between;

  &__btn {
    padding: 24px 0 0 0;
  }
}
</style>
