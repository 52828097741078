export const updates = [
  {
    title: 'Soilbox 1.2.0',
    list: [
      'Улучшен и оптимизирован модуль экспорта данных',
      'Добавлен статус процесса синхронизации данных (отображается в карточке скважины, если синхронизация не завершена)',
      'Добавлены новые атрибуты в импорт данных: <span class="su-code">Задание-офис</span>, <span class="su-code">Примечание-офис</span>',
      'Добавлена полноценная работа с удаленными из системы пользователями',
      'Добавлены атрибуты грунтов в ведомость проб в онлайн-таблицах: <span class="su-code">Полное описание</span>, <span class="su-code">Сокращенное описание</span>, <span class="su-code">Состояние</span>, <span class="su-code">Состояние мерзлого грунта</span>, <span class="su-code">Льдистость</span>, <span class="su-code">Плотность</span>, <span class="su-code">Содержание ОВ</span>, <span class="su-code">Карбонатность</span>, <span class="su-code">Ожелезненность</span>, <span class="su-code">Загрязненность</span>',
      'Добавлены новые атрибуты в ведомость проб Excel: <span class="su-code">Дата начала бурения</span>, <span class="su-code">Дата окончания бурения</span>',
      'Добавлен раздел "Последние обновления системы"',
      'Добавлен отдельный атрибут <span class="su-code">Cтепень водонасыщения</span> для крупнообломочных грунтов',
      'Исправлен баг при создании скважины пользователем с ролью Геолог',
      'Исправлены мелкие неточности и внесены улучшения по всей системе'
    ],
    version: '1.2.0',
    date: '08.11.2024',
    lastUpdate: true
  }
]
