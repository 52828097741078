import rDate from 'ritm-date'
import { useSyncStore, useMainStore, useObjectsStore, useAuthStore } from '@/stores'
import router from '@/router'
import db from '@/libs/db'
import { orderBy } from 'natural-orderby'

export * from './errors'
export * from './map'
export * from './objects'
export * from './soils'
export * from './services'
export * from './type-configs'
export * from './strings'
export * from './requests'
export * from './excavation'
export * from './recon'
export * from './data-sync'
export * from './custom-dms-mask'
export * from './internet-speed-check'
export * from './geologists'

export const getDataFromLS = (name, data) =>
  localStorage.getItem(name) ? JSON.parse(localStorage.getItem(name)) : data

export const saveDataToLS = (name, data) => localStorage.setItem(name, JSON.stringify(data))

export const removeDataFromLS = (key) => {
  localStorage.removeItem(key)
}

export const toggleBodyTheme = (newTheme) => {
  newTheme = newTheme || 'dark-theme'
  const oldClass = newTheme === 'dark-theme' ? 'light-theme' : 'dark-theme'

  document.body.classList.remove(oldClass)
  document.body.classList.add(newTheme)
}

export const toggleBodyMq = (current, prev) => {
  current = current || 'lg'

  if (prev) {
    document.body.classList.remove(prev)
  }

  document.body.classList.add(current)
}

export const getImageSrc = (path) => {
  // path example - 'assets/images/logo/soilbox_logo.png'
  return new URL(`../${path}`, import.meta.url).href
}

export function getOS() {
  let userAgent = window.navigator.userAgent,
    platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (/Linux/.test(platform)) {
    os = 'Linux'
  }

  return os
}

export const roundValue = (value, decimals = 2) =>
  Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)

export const parseDate = (date) => (date ? rDate(date).format('DD.MM.YYYY') : '-')
export const parseDatetime = (date) => (date ? rDate(date).format('DD.MM.YYYY HH:mm') : '-')

export const searchMatches = (from, directory, compareBy, key, ifKeyIsEmpty) => {
  return from.map((itemFrom) => {
    const foundItem = directory?.find((item) => {
      return item[compareBy] === itemFrom
    })

    if (!foundItem) {
      return null
    }

    if (foundItem[key]) {
      return foundItem[key]
    } else {
      return foundItem[ifKeyIsEmpty]
    }
  })
}

export const dataURLtoBlob = (dataurl) => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new Blob([u8arr], { type: mime })
}

export const blobToBase64 = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader()

    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export const getSourceTitle = (source) => {
  const { title, number, author, year } = source

  let sourceTitle = number ? `${number}. ${title}` : title

  if (author) {
    sourceTitle += `, ${author}`
  }
  if (year) {
    sourceTitle += `, ${year}`
  }

  return sourceTitle
}

export const goToRoute = async (path, route) => {
  const syncStore = useSyncStore()
  const mainStore = useMainStore()
  const objectsStore = useObjectsStore()
  if (syncStore.loading || path === route.fullPath) return

  objectsStore.setField('activeObject', null)
  const wasObject =
    route.fullPath.includes('object') ||
    route.fullPath.includes('excavation') ||
    route.fullPath.includes('recon')
  const wasBook = route.fullPath.includes('book')
  const field = wasObject ? 'lastObjectPath' : wasBook ? 'lastBookPath' : ''

  mainStore.setLastRoutePath([field, route.fullPath])

  if (path.includes('object')) {
    let routePath = !wasObject ? mainStore.lastRoutePaths.lastObjectPath || path : path

    if (routePath.includes('excavation?id=idb_')) {
      const idbId = routePath.split('idb_')[1]
      const excavItem = await db.excavations.get({ id: Number(idbId) })
      if (excavItem.server_id) {
        router.push({ name: 'excavation', query: { id: excavItem.server_id } })
      }
      return
    }

    if (path === '/app/data/objects' && route.fullPath === '/app/data/objects/list') return

    router.push(routePath)
  } else if (path.includes('books')) {
    if (path === '/app/books' && route.fullPath === '/app/books/list') return
    const routePath = !wasBook ? mainStore.lastRoutePaths.lastBookPath || path : path
    if (routePath === route.fullPath) return

    router.push(routePath)
  } else {
    router.push(path)
  }
}

export const hasDataPermissions = (geologist) => {
  const authStore = useAuthStore()
  const userId = authStore?.user?.id

  return authStore.isAdmin || geologist === userId || geologist === null
}

export const getDataPointNameByType = (type) => {
  switch (type) {
    case 101:
      return 'excavation'
    default:
      return 'recon'
  }
}

export const reloadWindow = () => {
  window.location.reload()
}

export const filterData = (list, filters, hasFilterType) => {
  if (!list.length) return []

  const sourcesTypesMap = {
    201: 'recon',
    101: 'excavations'
  }

  const filtersValues = filters.map((f) => ({
    id: f.id,
    ids: f.filterValues.filter((e) => e.value).map((e) => e.id)
  }))

  return list.filter((e) => {
    return filtersValues.every((f) => {
      if (hasFilterType && f.id === 'type') {
        const type = sourcesTypesMap[e.type]
        return type && f.ids.includes(type)
      }

      if (f.id === 'geologist') {
        const includeNull = f.ids.includes(null)
        if (includeNull && e[f.id] === null) return true
        if (!includeNull && e[f.id] === null) return false
      }

      return f.ids.includes(e[f.id])
    })
  })
}

export const filterAndSortList = (list = [], filterText = '', sortValue, filterFields = []) => {
  const { value, order } = sortValue
  const sortedList = orderBy(list, [(v) => v[value]], [order])

  return sortedList?.filter((e) => {
    return filterFields.some((field) => e[field]?.trim().toLowerCase().includes(filterText))
  })
}

export const getUuid = () => {
  return self.crypto.randomUUID()
}
