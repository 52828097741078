import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores'
import { getDataFromLS } from '@/utils'

import AuthComponent from '@/modules/auth/auth-page.vue'
import AppComponent from '@/modules/app/app-page.vue'
import DataComponent from '@/modules/app/data/data-page.vue'

import ObjectsComponent from '@/modules/app/data/objects/objects-page.vue'
import ObjectsListComponent from '@/modules/app/data/objects/list/objects-list-page.vue'
import ObjectComponent from '@/modules/app/data/objects/object/object-page.vue'
import ObjectInfoComponent from '@/modules/app/data/objects/object/modules/info-page/info-page.vue'
import ObjectDataComponent from '@/modules/app/data/objects/object/modules/data-page/data-page.vue'
import ObjectMapComponent from '@/modules/app/data/objects/object/modules/map-page.vue'

import ExcavationComponent from '@/modules/app/data/excavation/excavation-page.vue'
import ReconnaissanceComponent from '@/modules/app/data/recon/recon-page.vue'

import MobileMenuComponent from '@/modules/app/mobile-menu/mobile-menu-page.vue'
import ProfileComponent from '@/modules/app/profile/profile-page.vue'
import HomeComponent from '@/modules/app/home/home-page.vue'
import BooksComponent from '@/modules/app/books/books-page.vue'
import NotFoundComponent from '@/modules/app/not-found/not-found-page.vue'

import PrintComp from '@/modules/print/print-page.vue'
import PrintDashboardComp from '@/modules/print/dashboard/dashboard-page.vue'

const isAuthenticated = (to, from, next) => {
  const authStore = useAuthStore()

  if (authStore.isAuthenticated) {
    next()
    return
  }

  next('/login')
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      name: 'all',
      path: '/:pathMatch(.*)*',
      redirect: '/app'
    },
    {
      path: '/login',
      name: 'login',
      component: AuthComponent
    },
    {
      path: '/print',
      name: 'print',
      component: PrintComp,
      beforeEnter: isAuthenticated,
      redirect: '/print/dashboard',
      children: [
        {
          path: 'dashboard',
          name: 'dashboard-print',
          component: PrintDashboardComp
        }
      ]
    },
    {
      path: '/app',
      name: 'app',
      component: AppComponent,
      beforeEnter: isAuthenticated,
      redirect: '/app/data',
      children: [
        {
          path: 'data',
          name: 'data',
          component: DataComponent,
          redirect: '/app/data/objects',
          children: [
            {
              path: 'objects',
              component: ObjectsComponent,
              redirect: '/app/data/objects/list',
              children: [
                {
                  path: 'list',
                  name: 'objects-list',
                  component: ObjectsListComponent
                },
                {
                  path: ':id',
                  name: 'object',
                  redirect: (to) => {
                    const lastQueryes = getDataFromLS('lastQueryDataPageValue')

                    return {
                      name: 'object-data',
                      params: {
                        id: to.params.id
                      },
                      query: { ...to.query, ...lastQueryes }
                    }
                  },
                  component: ObjectComponent,
                  children: [
                    {
                      path: 'info',
                      name: 'object-info',
                      component: ObjectInfoComponent
                    },
                    {
                      path: 'data',
                      name: 'object-data',
                      component: ObjectDataComponent
                    },
                    {
                      path: 'map',
                      name: 'object-map',
                      component: ObjectMapComponent
                    },
                    {
                      path: 'sheets',
                      name: 'object-sheets',
                      redirect: { name: 'sheets-excavations' },
                      component: () =>
                        import(
                          '@/modules/app/data/objects/object/modules/sheets-page/sheets-page.vue'
                        ),
                      children: [
                        {
                          path: 'excavations',
                          name: 'sheets-excavations',
                          component: () =>
                            import(
                              '@/modules/app/data/objects/object/modules/sheets-page/modules/excavations/excavations-sheets.vue'
                            )
                        },
                        {
                          path: 'samples',
                          name: 'sheets-samples',
                          component: () =>
                            import(
                              '@/modules/app/data/objects/object/modules/sheets-page/modules/samples/samples-sheets.vue'
                            )
                        },
                        {
                          path: 'recons',
                          name: 'sheets-recons',
                          component: () =>
                            import(
                              '@/modules/app/data/objects/object/modules/sheets-page/modules/recons/recons-sheets.vue'
                            )
                        }
                      ]
                    },
                    {
                      path: 'dashboard',
                      name: 'object-dashboard',
                      component: () =>
                        import(
                          '@/modules/app/data/objects/object/modules/dashboard-page/dashboard-page.vue'
                        )
                    }
                  ]
                }
              ]
            },
            {
              path: 'excavation',
              name: 'excavation',
              component: ExcavationComponent
            },
            {
              path: 'recon',
              name: 'recon',
              component: ReconnaissanceComponent
            }
          ]
        },
        {
          path: 'profile',
          name: 'profile',
          component: ProfileComponent
        },
        {
          path: 'home',
          name: 'home',
          component: HomeComponent
        },
        {
          path: 'menu',
          name: 'mobile-menu',
          component: MobileMenuComponent
        },
        {
          path: 'books',
          name: 'books',
          component: BooksComponent,
          redirect: '/app/books/list',
          children: [
            {
              path: 'list',
              name: 'books-list',
              component: () => import('@/modules/app/books/books-list-page.vue')
            },
            {
              path: ':id',
              name: 'book',
              component: () => import('@/modules/app/books/book-page.vue')
            }
          ]
        },
        {
          path: 'meta-data',
          name: 'meta-data',
          component: () => import('@/modules/app/meta-data/meta-data-page.vue')
        },
        {
          path: 'not-found',
          name: 'not-found',
          component: NotFoundComponent
        }
      ]
    }
  ]
})

export default router
