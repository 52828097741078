import { useRoute, useRouter } from 'vue-router'
import { useRequests } from '@/composables'
import db from '@/libs/db'
import { saveToRecentList } from '@/utils/recent'
import { checkStorage, updateStorage } from '@/libs/db/helpers'
import { useExcavationStore, useMainStore, useServicesStore, useObjectsStore } from '@/stores'

export class DataController {
  constructor() {
    this.excavationStore = useExcavationStore()
    this.mainStore = useMainStore()
    this.servicesStore = useServicesStore()
    this.objectsStore = useObjectsStore()
    this.route = useRoute()
    this.router = useRouter()
    this.getRequest = useRequests().getRequest
    this.object = {}

    this.updateHandleFnMap = {
      excavations: this.getExcavations.bind(this),
      all: this.getExcavationAndSoilsData.bind(this),
      soils: this.getSoils.bind(this),
      samples: this.getSamples.bind(this),
      groundwater: this.getGroundwater.bind(this),
      coreboxes: this.getCoreboxes.bind(this),
      images: this.getExcavationImages.bind(this)
    }
  }

  async loadAllAssets(fromType = 'initial') {
    await this.getExcavation()
    await this.getExcavationImages()

    if (fromType === 'initial') {
      await this.getExcavations()
    }

    await this.getSoils()
    await this.getSamples()
    await this.getGroundwater()
    await this.getCoreboxes()
  }

  async loadSimpleAssetOnline(table, storeField) {
    try {
      const url = `excavations/${this.parsedId()}/${table}/`
      let server

      const idb = await db[table]
        .where({
          excavation_id: this.excavationId()
        })
        .toArray()

      if (!this.isIdb()) {
        server = await this.getRequest(url)
      }

      this.excavationStore.setField(storeField, idb)

      const filter = { field: 'excavation_id', value: this.excavationId() }

      await checkStorage(table, this.excavationStore[storeField], server, filter)
      await updateStorage(table, this.excavationStore[storeField], server, filter)
    } catch (e) {
      throw new Error(e)
    }
  }

  async loadSimpleAssetOffline(table, list) {
    try {
      const items = await db[table].where({ excavation_id: this.excavationId() }).toArray()

      this.excavationStore.setField(list, items)
    } catch (e) {
      throw new Error(e)
    }
  }

  updateHandler(val) {
    if (!this.excavationId()) return

    if (!this.updateHandleFnMap[val]) {
      this.getExcavation()
      return
    }

    this.updateHandleFnMap[val]()
  }

  getExcavationAndSoilsData() {
    this.getExcavation()
    this.getSoils()
  }

  async getExcavation() {
    if (!this.excavationId()) return
    if (this.mainStore.isOnline && !this.mainStore.noSyncMode) await this.loadExcavOnline()
    else await this.loadExcavOffline()
  }

  async getExcavationImages() {
    if (!this.excavationId()) return
    if (this.mainStore.isOnline && !this.mainStore.noSyncMode) await this.loadExcavImagesOnline()
    else await this.loadExcavImagesOffline()
  }

  async getExcavations() {
    if (!this.excavationId()) return
    if (this.mainStore.isOnline && !this.mainStore.noSyncMode) await this.loadExcavsOnline()
    else await this.loadExcavsOffline()
  }

  async getSoils() {
    if (!this.excavationId()) return
    if (this.mainStore.isOnline && !this.mainStore.noSyncMode) await this.loadSoilsOnline()
    else await this.loadSimpleAssetOffline('soils', 'soilsList')
  }

  async getSamples() {
    if (!this.excavationId()) return
    if (this.mainStore.isOnline && !this.mainStore.noSyncMode) await this.loadSamplesOnline()
    else await this.loadSamplesOffline()
  }

  async getGroundwater() {
    if (!this.excavationId()) return
    if (this.mainStore.isOnline && !this.mainStore.noSyncMode)
      await this.loadSimpleAssetOnline('groundwater', 'groundwaterList')
    else await this.loadSimpleAssetOffline('groundwater', 'groundwaterList')
  }

  async getCoreboxes() {
    if (!this.excavationId()) return
    if (this.mainStore.isOnline && !this.mainStore.noSyncMode) await this.loadCoreboxesOnline()
    else await this.loadSimpleAssetOffline('coreboxes', 'coreboxesList')
  }

  async loadExcavImagesOnline() {
    try {
      const images = await this.getRequest(`excavations/${this.parsedId()}/images/`)
      this.excavationStore.setField('excavationImages', images)
    } catch (e) {
      throw new Error(e)
    }
  }

  async loadExcavImagesOffline() {
    try {
      const images = []
      const idb = await db.images
        .where({
          table: 'excavations',
          item_id: this.excavationStore.active?.id
        })
        .toArray()

      idb.forEach((e) => {
        images.push({
          ...e,
          blob: e.image.file
        })
      })

      this.excavationStore.setField('excavationImages', images)
    } catch (e) {
      throw new Error(e)
    }
  }

  async loadExcavOffline() {
    try {
      const filterField = this.isIdb() ? 'id' : 'server_id'
      const excavation = await db.excavations.where({ [filterField]: this.parsedId() }).first()

      if (!excavation) {
        this.router.push('/app/not-found')
        return
      }

      saveToRecentList('recentExcavsId', excavation.id)

      this.excavationStore.setField('active', excavation)
      await this.loadObject(excavation?.object_id)
    } catch (e) {
      throw new Error(e)
    }
  }

  async loadExcavOnline() {
    try {
      let excavation

      if (this.isIdb()) {
        excavation = await db.excavations.get(this.parsedId())
      } else {
        const idb = await db.excavations
          .where({
            server_id: this.parsedId()
          })
          .first()

        excavation = await this.getRequest(`excavations/${this.parsedId()}/`)
        excavation.id = idb?.id
        excavation.server_id = this.parsedId()
        excavation.object_id = excavation.object_ref_id || idb?.object_id

        await db.updateObject('excavations', excavation, false, true)
      }

      if (!excavation) return
      saveToRecentList('recentExcavsId', excavation.id)

      this.excavationStore.setField('active', excavation)

      await this.loadObject(excavation?.object_id)
    } catch (e) {
      this.router.push('/app/not-found')
      throw new Error(e)
    }
  }

  async loadExcavsOnline() {
    try {
      let object
      const objectId = this.excavation()?.object_id
      if (!objectId) return
      const isIdbObject = String(objectId).includes('idb_')
      const parsedObjectId = !isIdbObject ? Number(objectId) : Number(objectId.replace('idb_', ''))

      if (isIdbObject) {
        object = await db.objects.get(parsedObjectId)
      } else {
        const idbObject = await db.objects
          .where({
            server_id: parsedObjectId
          })
          .first()
        const idbId = idbObject?.id
        object = await this.getRequest(`objects/${parsedObjectId}/`)

        object.id = idbId
        object.server_id = parsedObjectId
        if (idbId) {
          await db.updateObject('objects', object)
        } else {
          throw new Error('debug loadExcavsOnline: ', idbId, idbObject)
        }
      }

      if (!object) return
      this.setProjectMetadata(object)

      this.objectsStore.setField('active', object)

      const filter = { field: 'object_id', value: parsedObjectId }

      await db.updateStoreData('excavations', filter)

      const server = await this.getRequest(`objects/${parsedObjectId}/excavations/`)

      await checkStorage('excavations', this.objectsStore.excavationsList, server, filter)
      await updateStorage('excavations', this.objectsStore.excavationsList, server, filter)
    } catch (e) {
      throw new Error(e)
    }
  }

  async loadExcavsOffline() {
    try {
      const objectId = this.excavation()?.object_id
      const isIdbObject = String(objectId).includes('idb_')
      const filterField = isIdbObject ? 'id' : 'server_id'
      const parsedObjectId = !isIdbObject ? Number(objectId) : Number(objectId.replace('idb_', ''))

      const object = await db.objects.where({ [filterField]: parsedObjectId }).first()

      if (!object) return

      this.setProjectMetadata(object)

      this.objectsStore.setField('active', object)

      const filter = { field: 'object_id', value: parsedObjectId }

      await db.updateStoreData('excavations', filter)
    } catch (e) {
      throw new Error(e)
    }
  }

  async loadSoilsOnline() {
    try {
      const url = `excavations/${this.parsedId()}/soils/`
      let server

      const idb = await db.soils
        .where({
          excavation_id: this.excavationId()
        })
        .toArray()

      if (!this.isIdb()) {
        server = await this.getRequest(url)
      }

      this.excavationStore.setField('soilsList', idb)
      const filter = { field: 'excavation_id', value: this.excavationId() }

      await checkStorage('soils', this.excavationStore.soilsList, server, filter)
      await updateStorage('soils', this.excavationStore.soilsList, server, filter)

      await Promise.all(
        server.map(async (s) => {
          const { inclusions, interlayers } = s
          const soilIdbId = this.excavationStore.soilsList?.find((is) => is.server_id === s.id)?.id

          if (inclusions.length) {
            const idbList = await db.inclusions
              .where({
                soil_server_id: s.id
              })
              .toArray()
            const filter = { field: 'soil_server_id', value: s.id }
            await checkStorage(
              'inclusions',
              idbList,
              inclusions.map((inc) => {
                inc.soil_id = soilIdbId
                return inc
              }),
              filter,
              true
            )
            await updateStorage('inclusions', idbList, inclusions, filter, true)
          }

          if (interlayers.length) {
            const idbList = await db.interlayers
              .where({
                soil_server_id: s.id
              })
              .toArray()
            const filter = { field: 'soil_server_id', value: s.id }
            await checkStorage(
              'interlayers',
              idbList,
              interlayers.map((inc) => {
                inc.soil_id = soilIdbId
                return inc
              }),
              filter,
              true
            )
            await updateStorage('interlayers', idbList, interlayers, filter, true)
          }
        })
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  async loadCoreboxesOnline() {
    try {
      // this.excavationStore.setField('coreboxesList', [])

      const url = `excavations/${this.parsedId()}/coreboxes/`
      let server

      const idb = await db.coreboxes
        .where({
          excavation_id: this.excavationId()
        })
        .toArray()

      if (!this.isIdb() && this.parsedId()) {
        server = await this.getRequest(url)
      }

      this.excavationStore.setField('coreboxesList', idb)
      const filter = { field: 'excavation_id', value: this.excavationId() }

      await checkStorage('coreboxes', this.excavationStore.coreboxesList, server, filter)
      await updateStorage('coreboxes', this.excavationStore.coreboxesList, server, filter)
    } catch (e) {
      throw new Error(e)
    }
  }

  async loadSamplesOnline() {
    try {
      // this.excavationStore.setField('samplesList', [])

      const url = `excavations/${this.parsedId()}/samples/`
      let server

      const idb = await db.samples
        .where({
          excavation_id: this.excavationId()
        })
        .toArray()

      if (!this.isIdb()) {
        server = await this.getRequest(url)
      }

      this.excavationStore.setField('samplesList', idb)
      const filter = { field: 'excavation_id', value: this.excavationId() }

      await checkStorage('samples', this.excavationStore.samplesList, server, filter, url)
      await updateStorage('samples', this.excavationStore.samplesList, server, filter)
    } catch (e) {
      throw new Error(e)
    }
  }

  async loadSamplesOffline() {
    try {
      // this.excavationStore.setField('samplesList', [])

      const samples = await db.samples.where({ excavation_id: this.excavationId() }).toArray()

      this.excavationStore.setField('samplesList', samples)
    } catch (e) {
      throw new Error(e)
    }
  }

  async loadObject(id) {
    try {
      if (!id) return

      const object = await db.objects.where('server_id').equals(id).first()

      if (!object) return

      this.object = object
      this.objectsStore.setField('activeObject', object)
      this.setProjectMetadata(object)
    } catch (e) {
      throw new Error(e)
    }
  }

  setProjectMetadata({ geologists, bore_machines, bore_masters }) {
    const object_users = this.services()?.users?.filter((e) => geologists?.find((g) => g === e.id))
    const object_bore_machines = this.services()?.bore_machines?.filter((e) =>
      bore_machines?.find((g) => g === e.id)
    )
    const object_bore_masters = this.services()?.bore_masters?.filter((e) =>
      bore_masters?.find((g) => g === e.id)
    )

    this.servicesStore.setService(['object_users', object_users])
    this.servicesStore.setService(['object_bore_machines', object_bore_machines])
    this.servicesStore.setService(['object_bore_masters', object_bore_masters])
  }

  services() {
    const { users, bore_machines, bore_masters } = this.servicesStore

    return { users, bore_machines, bore_masters }
  }

  excavationId() {
    return this.route.query.id
  }

  isIdb() {
    return String(this.excavationId()).includes('idb_')
  }

  parsedId() {
    const id = !this.isIdb() ? this.excavationId() : this.excavationId().replace('idb_', '')
    return id ? Number(id) : null
  }

  goBack() {
    if (this.objectId()) {
      this.router.push(`/app/data/objects/${this.objectId()}`)
    } else {
      this.router.push('/app/data/objects/list')
    }
  }

  excavation() {
    return this.excavationStore.active || {}
  }

  objectId() {
    return this.excavation?.object_id
  }
}
