<script setup>
import { computed, onMounted, ref, watch, inject } from 'vue'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import { useMq } from 'vue3-mq'

import { useRequests } from '@/composables'
import { useExcavationStore, useMainStore } from '@/stores'
import db from '@/libs/db'
import { blobToBase64, deleteObject, getExcavationsChanges, getUuid } from '@/utils'
import { coreboxesTemplate } from './config'

import WikiTip from '@/components/s-wiki-tip/s-wiki-tip.vue'
import SAttachments from '@/components/s-attachments/s-attachments.vue'

const props = defineProps({
  excavation: {
    type: Object,
    required: true
  },
  editorName: {
    type: String,
    default: null
  },
  isVisible: {
    type: Boolean,
    default: false
  },
  activeObject: {
    type: Object,
    default: null
  },
  soilsList: {
    type: Array,
    default: () => []
  }
})

const emits = defineEmits(['toggle'])

const mq = useMq()
const excavationStore = useExcavationStore()
const mainStore = useMainStore()
const { postRequest, putRequest } = useRequests()
const $notify = inject('$notify')

const loading = ref(false)
const coreboxesTemplateLocal = ref(null)
const initSource = ref(null)
const files = ref([])
const images = ref([])
const cloneImages = ref([])

const hasChanges = computed(() => {
  return !isEqual(initSource.value, coreboxesTemplateLocal.value)
})

const title = computed(() => {
  return props.activeObject ? 'Редактирование фото керна' : 'Добавление фото керна'
})

const showUploader = computed(() => {
  const foot_d0 = coreboxesTemplateLocal.value?.foot_d0
  const foot_d1 = coreboxesTemplateLocal.value?.foot_d1

  return (!foot_d0 && foot_d0 !== 0) || !foot_d1
})

const disabled = computed(() => {
  if (loading.value) return true
  const foot_d0 = coreboxesTemplateLocal.value?.foot_d0
  const foot_d1 = coreboxesTemplateLocal.value?.foot_d1
  const image = coreboxesTemplateLocal.value?.image
  const d0 = !!foot_d0 || foot_d0 === 0
  const d1 = !!foot_d1 || foot_d1 === 0

  return !d0 || !d1 || !image
})

const minValue = computed(() => {
  return 0
})

const maxValue = computed(() => {
  let max = 0

  props.soilsList?.forEach((e) => {
    max = e.foot_d > max ? e.foot_d : max
  })

  return max
})

watch(
  () => files.value,
  (newValue) => {
    coreboxesTemplateLocal.value.image = newValue?.[0]?.file || null
    coreboxesTemplateLocal.value.title = newValue?.[0]?.name || ''
  },
  { deep: true }
)

watch(
  () => coreboxesTemplateLocal.value?.foot_d0,
  (newValue) => {
    inputHandler(newValue || 0)
  },
  { deep: true }
)

const inputHandler = (val) => {
  if (!val && val !== 0) {
    coreboxesTemplateLocal.value.foot_d1 = null
  } else {
    const initVal = Number(val)

    if (!props.activeObject) {
      const sum = Number((initVal + 5)?.toFixed(2))

      coreboxesTemplateLocal.value.foot_d1 = sum > maxValue.value ? maxValue.value : sum
    }
  }
}

const handleDelete = async () => {
  await deleteObject(coreboxesTemplateLocal.value, 'coreboxes', updateCoreboxes)
  emits('toggle')
}

const updateCoreboxes = () => {
  excavationStore.setField('updateCoreboxes', true)
}

const saveHandler = () => {
  if (!props.activeObject) {
    createHandler()
  } else {
    updateHandler()
  }
}

onMounted(() => {
  if (props.activeObject) {
    coreboxesTemplateLocal.value = cloneDeep(props.activeObject)

    const { image_url, thumbnail_url, server_id, image, title } = props.activeObject
    coreboxesTemplateLocal.value.image = coreboxesTemplateLocal.value.image_url

    if (image_url && server_id) {
      images.value.push({
        id: server_id,
        image_url,
        thumbnail_url
      })
    }
    if (image) {
      files.value?.push({
        file: image,
        name: title
      })
    }
    cloneImages.value = cloneDeep(images.value)
  } else {
    coreboxesTemplateLocal.value = coreboxesTemplate()
    inputHandler(0)
  }

  initSource.value = cloneDeep(coreboxesTemplateLocal.value)
})

const createHandler = async () => {
  loading.value = true

  try {
    coreboxesTemplateLocal.value.date_front = new Date()
    coreboxesTemplateLocal.value.uuid = getUuid()
    const data = cloneDeep(coreboxesTemplateLocal.value)
    let idbData = cloneDeep(coreboxesTemplateLocal.value)
    const excavationId = props.excavation?.server_id || `idb_${props.excavation.id}`

    idbData.excavation_id = String(excavationId)

    if (mainStore.isOnline && !mainStore.noSyncMode && props.excavation?.server_id) {
      if (data.image) {
        data.image_b64 = await blobToBase64(data.image)
      }
      delete data.image
      const response = await postRequest(`excavations/${excavationId}/coreboxes/`, data)

      if (!response) return

      idbData = cloneDeep(response)
      idbData.server_id = response.id
      idbData.excavation_id = String(excavationId)
      delete idbData.id
    }

    const idbId = await db.addObject('coreboxes', idbData, {
      field: 'excavation_id',
      value: excavationId
    })
    coreboxesTemplateLocal.value.id = idbId

    if (!mainStore.isOnline || mainStore.noSyncMode) {
      await db.created.add({
        table: 'coreboxes',
        date: new Date(),
        item_id: idbId
      })
      getExcavationsChanges()
    }

    const title = 'Создание'
    const message = 'Фото керна успешно сохранено'
    $notify({
      title,
      message,
      type: 'success'
    })
  } catch (e) {
    const title = 'Ошибка'
    const message = 'Произошла ошибка при создании фото керна'
    $notify({
      title,
      message,
      type: 'error'
    })
  } finally {
    loading.value = false
    emits('toggle')
  }
}

const updateHandler = async () => {
  loading.value = true

  try {
    coreboxesTemplateLocal.value.date_front = new Date()

    const data = cloneDeep(coreboxesTemplateLocal.value)
    let idbData = cloneDeep(coreboxesTemplateLocal.value)
    const excavationId = props.excavation?.server_id || `idb_${props.excavation.id}`

    idbData.excavation_id = String(excavationId)
    if (mainStore.isOnline && !mainStore.noSyncMode && coreboxesTemplateLocal.value.server_id) {
      delete data.server_id
      delete data.excavation_id
      if (data.image?.type) {
        data.image_b64 = await blobToBase64(data.image)
      }
      delete data.image
      delete data.image_url
      delete data.thumbnail_url
      data.id = coreboxesTemplateLocal.value.server_id
      const response = await putRequest(
        `coreboxes/${coreboxesTemplateLocal.value.server_id}/`,
        data
      )

      if (!response) return

      idbData = cloneDeep(response)
      idbData.server_id = response.id
      idbData.excavation_id = String(excavationId)
      idbData.id = coreboxesTemplateLocal.value.id
    }

    await db.updateObject('coreboxes', idbData, {
      field: 'excavation_id',
      value: excavationId
    })

    if ((!mainStore.isOnline || mainStore.noSyncMode) && excavationId) {
      const updatedItems = await db.updated.where('table').equals('coreboxes').toArray()
      const inUpdated = !!updatedItems.find((e) => e.item_id === idbData.id)
      const createdItems = await db.created.where('table').equals('coreboxes').toArray()
      const inCreated = !!createdItems.find((e) => e.item_id === idbData.id)

      if (!inUpdated && !inCreated) {
        await db.updated.put({
          table: 'coreboxes',
          item_id: idbData.server_id,
          date: new Date()
        })
        getExcavationsChanges()
      }
    }
    excavationStore.setField('updateCoreboxes', true)
    const title = 'Редактирование'
    const message = 'Фото керна успешно изменено'
    $notify({
      title,
      message,
      type: 'success'
    })
  } catch (e) {
    const title = 'Ошибка'
    const message = 'Произошла ошибка при редактировании фото керна'
    $notify({
      title,
      message,
      type: 'error'
    })
  } finally {
    loading.value = false
    emits('toggle')
  }
}

const additionalText = 'Рекомендуется загружать фотографии в соотношении 4:3'

const wikiTipData = {
  color: 'placeholder',
  title: 'Фотодокументация керна',
  description:
    'Фотодокументацию керна производят после его очистки от шлама или среза нарушенной пе­риферийной зоны. На фотографируемый керн и образцы устанавливают этикетки с номером выработки и интервалом отбора. Фотодокументирование керна, образцов при проходке горной выработки проводят в мерзлом состоянии после очистки керна от бурового шлама и растепленного грунта.',
  source:
    'ГОСТ Р 58325. Грунты. Полевое Описание, п. 4.3.3. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
}
</script>

<template>
  <s-modal
    :title="title"
    :show="isVisible"
    :fullscreen="mq.current !== 'lg'"
    :min-height="460"
    :confirm-condition="hasChanges"
    confirm-on-cancel
    @close="emits('toggle')"
  >
    <div v-loading="loading" class="coreboxes-editor">
      <div class="coreboxes-editor-block">
        <s-title type="small"> Интервал кернового ящика </s-title>
        <div v-if="coreboxesTemplateLocal" class="coreboxes-editor-row">
          <s-number-input
            v-model="coreboxesTemplateLocal.foot_d0"
            label="От, м"
            :min="minValue"
            :max="maxValue"
            placeholder="0.0"
          />
          <s-number-input
            v-model="coreboxesTemplateLocal.foot_d1"
            label="До, м"
            :min="minValue"
            :max="maxValue"
            placeholder="3.0"
          />
        </div>
      </div>
      <div v-if="!showUploader" class="coreboxes-editor-block">
        <div class="coreboxes-editor__wiki-tip">
          <s-title type="small"> Фотография </s-title>
          <wiki-tip :data="wikiTipData">
            <s-icon name="fa-circle-question" :color="`var(--${wikiTipData.color})`" />
          </wiki-tip>
        </div>
        <s-attachments
          v-loading="loading"
          label="Загрузка фото"
          no-title
          :source="files"
          :images="images"
          :additionalText="additionalText"
        />
        <s-input v-model="coreboxesTemplateLocal.comments" label="Комментарий" type="textarea" />
      </div>
    </div>
    <template #footer>
      <div :class="['coreboxes-editor-footer', { exist: activeObject }]">
        <s-button
          v-if="activeObject"
          icon="trash-can"
          simple
          icon-color="var(--error)"
          @click="handleDelete"
        />
        <s-button type="success" :disabled="disabled" @click="saveHandler">
          {{ activeObject ? 'Сохранить' : 'Создать' }}
        </s-button>
      </div>
    </template>
  </s-modal>
</template>

<style lang="scss">
.coreboxes-editor {
  display: grid;
  align-content: start;
  grid-gap: 2rem;
  height: 100%;
  overflow: auto;

  &__wiki-tip {
    display: flex;
    justify-content: space-between;
  }

  &-block {
    display: grid;
    align-content: start;
    grid-gap: 1rem;
  }

  &-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    grid-gap: 1rem;
  }

  &-footer {
    display: grid;

    &.exist {
      grid-template-columns: auto 1fr;
      grid-gap: 1rem;
    }
  }
}
</style>
