import { required, maxLength, email, helpers, minLength, sameAs } from '@vuelidate/validators'

export const basicInfoValidators = {
  first_name: {
    required: helpers.withMessage('Имя обязательно для заполнения', required),
    maxLength: helpers.withMessage('Имя должно содержать максимум 50 символов', maxLength(50))
  },
  last_name: {
    required: helpers.withMessage('Фамилия обязательна для заполнения', required),
    maxLength: helpers.withMessage('Фамилия должна содержать максимум 50 символов', maxLength(50))
  },
  email: {
    email: helpers.withMessage('Введите корректный адрес электронной почты', email),
    maxLength: helpers.withMessage(
      'Электронная почта должна содержать максимум 320 символов',
      maxLength(320)
    )
  }
}

export const passwordValidators = (form) => ({
  password: {
    minLength: helpers.withMessage('Пароль должен содержать минимум 8 символов', minLength(8)),
    maxLength: helpers.withMessage('Пароль должен содержать максимум 35 символов', maxLength(35))
  },
  confirmPassword: {
    sameAsPassword: helpers.withMessage('Пароли должны совпадать', sameAs(form.password))
  }
})
