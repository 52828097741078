export const objectDesktopNavigationLinks = [
  {
    title: 'Общее',
    icon: 'info-circle',
    routeName: 'object-info',
    tooltipText: 'Информация о проекте'
  },
  {
    title: 'Данные',
    icon: 'database',
    routeName: 'object-data',
    tooltipText: 'Данные',
    queryType: 'excavations',
    submenu: [
      {
        title: 'Все',
        queryType: 'all'
      },
      {
        title: 'Скважины',
        queryType: 'excavations'
      },
      {
        title: 'Точки наблюдения',
        queryType: 'recon'
      }
    ]
  },
  {
    title: 'Таблицы',
    icon: 'table',
    routeName: 'object-sheets',
    tooltipText: 'Таблицы'
  },
  {
    title: 'Доска',
    icon: 'chart-pie',
    routeName: 'object-dashboard',
    tooltipText: 'Доска'
  }
]

export const objectMobileNavigationLinks = [
  {
    title: 'О проекте',
    routeName: 'object-info'
  },
  {
    title: 'Данные',
    routeName: 'object-data'
  },
  {
    title: 'Карта',
    routeName: 'object-map'
  }
]
