<script setup>
import { computed, ref } from 'vue'
import { useServicesStore } from '@/stores/index.js'
import SInputRenderer from '@/components/s-input-renderer/s-input-renderer.vue'

const servicesStore = useServicesStore()

const props = defineProps({
  template: {
    type: Object,
    required: true
  },
  fields: {
    type: Array,
    required: true
  }
})

const services = computed(() => {
  const { clients, contractors } = servicesStore

  return {
    clients,
    contractors
  }
})

const sources = ref(props.template)
</script>

<template>
  <div class="contractors-info">
    <div class="contractors-info__block" v-for="field in fields" :key="field.title">
      <s-title type="small">{{ field.title }}</s-title>
      <s-input-renderer
        v-for="field in field.items"
        :key="field.id"
        :field="field"
        :source="sources"
        :services="services"
      />
    </div>
  </div>
</template>

<style lang="scss">
.contractors-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;

  & .date-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
