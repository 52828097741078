<script setup>
import { computed, onMounted, ref } from 'vue'
import { useMq } from 'vue3-mq'
import { useSyncStore, useMainStore } from '@/stores'
import { syncErrorHandler } from './helper'
import { syncAllExcav, syncAllData, getAllChanges, getMeasureInternetSpeed } from '@/utils'

import SyncContent from './components/sync-content.vue'

const syncStore = useSyncStore()
const mainStore = useMainStore()
const mq = useMq()

const showWarning = ref(false)

const noSync = computed(() => {
  return !!syncStore.changesList?.length || !!syncStore.dataChangesList?.length || false
})

const changesLoaded = computed(() => {
  return syncStore.changesLoaded || false
})

const clickHandler = (action) => {
  switch (action) {
    case 'sync':
      syncData()
      break
    case 'continue':
      mainStore.setNoSyncMode(true)
      showWarning.value = false
      break
  }
}

const syncData = async () => {
  try {
    syncStore.setField('loading', true)

    await getMeasureInternetSpeed()

    showWarning.value = false
    await syncAllExcav(syncStore.changesList)
    await syncAllData(syncStore.dataChangesList)
  } catch (e) {
    if (e?.response) {
      syncErrorHandler(e)
    }
  } finally {
    syncStore.setField('loading', false)
  }
}

onMounted(async () => {
  if (mainStore.isOnline && noSync.value && changesLoaded.value) {
    showWarning.value = true
  }

  await getAllChanges()
})

const modalTitleWarning = 'Восстановление онлайн-режима'
const alertText =
  'Настоятельно рекомендуем синхронизировать данные с сервером сразу же при появлении стабильного интернет-соединения, чтобы избежать потери данных'
</script>

<template>
  <div class="sync">
    <s-title type="title">
      Панель синхронизации
      {{ syncStore.internetSpeed ? `(скорость: ${syncStore.internetSpeed}kbs)` : '' }}
    </s-title>

    <sync-content @sync="clickHandler('sync')" />

    <s-modal
      :marginY="mq.current === 'lg' ? 48 : 0"
      :title="modalTitleWarning"
      no-close-button
      :show="showWarning"
      :fullscreen="mq.current !== 'lg'"
      :close-by-overlay="false"
    >
      <div class="sync__content">
        <s-title type="small">Внимание! На устройстве имеются несинхронизированные данные</s-title>
        <s-text>
          Вы можете синхронизировать эти данные с сервером или продолжить работу в оффлайн-режиме,
          накапливая данные на устройстве
        </s-text>
        <s-alert :title="alertText" icon="circle-exclamation" type="warning" />
      </div>
      <template #footer>
        <s-div :gap="1" stretch style="flex-direction: column">
          <s-button stretch @click="clickHandler('continue')">
            Продолжить в оффлайн-режиме
          </s-button>
          <s-button
            v-loading="syncStore.loading"
            stretch
            type="success"
            @click="clickHandler('sync')"
          >
            Синхронизировать данные
          </s-button>
        </s-div>
      </template>
    </s-modal>
  </div>
</template>

<style lang="scss">
.sync {
  display: grid;
  grid-gap: 1rem;
  align-content: start;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
