import { getExcavationsChanges } from './excavation'
import { getDataChanges } from './data.js'
import { useRequests } from '@/composables'
import { useSyncStore } from '@/stores'
export * from './excavation.js'
export * from './data.js'

export const getAllChanges = async () => {
  await getExcavationsChanges()
  await getDataChanges()
}

export const sendSyncProgress = async (item, syncStoreField = 'changesList', url) => {
  const syncStore = useSyncStore()
  const { patchRequest } = useRequests()

  const { item_id } = item

  if (!item_id) return

  const findedItem = syncStore[syncStoreField].find((i) => i.item_id === item_id)

  if (!findedItem) return

  const changesArray = []
  findedItem.updates.forEach((update) => {
    changesArray.push(update.title)
  })

  const data = {
    sync_status: 1,
    sync_progress: changesArray
  }

  try {
    await patchRequest(`${url}/${item_id}/`, data)
  } catch (e) {
    console.log(e)
    throw new Error(e)
  }
}

export const resetSyncProgress = async (item, dataUrl = 'excavations') => {
  const { patchRequest } = useRequests()

  const { item_id } = item

  if (!item_id) return

  const data = {
    sync_status: 2,
    sync_progress: []
  }

  await patchRequest(`${dataUrl}/${item_id}/`, data)
}
