<script setup>
import { ref } from 'vue'
import { useMq } from 'vue3-mq'
import WikiTipContent from './s-wiki-tip-content.vue'

defineProps({
  data: {
    type: Object,
    required: true
  }
})

const mq = useMq()

const isShowBottomSheep = ref(false)
</script>

<template>
  <div class="wiki-tip">
    <template v-if="mq.current === 'lg'">
      <div>
        <s-pop-tip width="320">
          <slot />
          <template #content>
            <wiki-tip-content :data="data" />
          </template>
        </s-pop-tip>
      </div>
    </template>
    <template v-else>
      <div>
        <div @click.stop="isShowBottomSheep = true">
          <slot />
        </div>
        <s-bottom-sheet :is-show="isShowBottomSheep" @close="isShowBottomSheep = false">
          <template #content>
            <wiki-tip-content :data="data" />
            <s-button stretch @click="isShowBottomSheep = false"> Понятно </s-button>
          </template>
        </s-bottom-sheet>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.wiki-tip {
  position: relative;
}
</style>
