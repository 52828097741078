<script setup>
import { computed, ref, watch } from 'vue'
import { useServicesStore } from '@/stores/index.js'

const serviceStore = useServicesStore()

const props = defineProps({
  template: {
    type: [Object, null],
    required: true
  },
  fields: {
    type: Array,
    required: true
  }
})

const getStatusServices = computed(() => {
  return serviceStore.object.statuses
})

const sources = ref(props.template)

watch(
  () => props.template,
  (newValue) => {
    sources.value = newValue
  }
)

const changeSelect = (value) => {
  sources.value.status = value
}
</script>

<template>
  <div v-if="sources" class="basic-information">
    <div v-for="field in fields" :key="field.id">
      <s-select
        :value="sources.status"
        @change="changeSelect"
        v-if="field.tag === 'select'"
        :options="getStatusServices"
        :selectLabel="field.label"
      />
      <s-switch
        v-else-if="field.tag === 'switch'"
        v-model="sources[field.title]"
        :label="field.label"
      />
      <s-input
        v-else
        :type="field.tagType"
        v-model="sources[field.title]"
        :label="field.label"
        :placeholder="field.placeholder"
        :maxlength="field.maxlength"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.basic-information {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
}
</style>
