import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useMainStore } from '@/stores'
import { getDataFromLS } from '@/utils'

export const useMapStore = defineStore('map', () => {
  const mainStore = useMainStore()

  const locationLoading = ref(false)
  const setLocationLoading = (value) => {
    locationLoading.value = value
  }

  const userLocation = ref(null)
  const userAbs = ref(null)
  const setLocation = (center, altitude) => {
    userLocation.value = center
    userAbs.value = altitude
  }

  const activeBaselayers = ref({
    'data-map': 'map',
    'data-map-editor': 'satelite',
    'dashboard-print-map': 'outdoors'
  })
  const getActiveBaselayerName = (module) => {
    const active = activeBaselayers.value[module]

    return active === 'map' ? mainStore.theme : active
  }
  const setBaselayer = (module, value) => {
    activeBaselayers.value[module] = value
  }

  const centerMapOnActive = ref(false)
  const setCenterMapOnActive = (value) => {
    centerMapOnActive.value = value
  }

  const settings = ref(
    getDataFromLS('mapSettings', {
      clusters: true,
      processes: true,
      planFactLocation: false
    })
  )
  const legend = ref(false)
  const toggleLegend = (value) => {
    legend.value = value
  }

  return {
    locationLoading,
    setLocationLoading,
    userLocation,
    userAbs,
    setLocation,
    activeBaselayers,
    getActiveBaselayerName,
    setBaselayer,
    centerMapOnActive,
    setCenterMapOnActive,
    settings,
    legend,
    toggleLegend
  }
})
